import request from '@/api/request'

export function listGpsDevicePortConfigByDeviceId (deviceId) {
  return request({
    url: 'gps/device/portConfig/listGpsDevicePortConfigByDeviceId/' + deviceId,
    method: 'get'
  })
}

export function deleteGpsDevicePortConfig (configId) {
  const req = {
    configId: configId
  }
  return request({
    url: 'gps/device/portConfig/deleteGpsDevicePortConfig',
    method: 'post',
    data: req
  })
}

export function insertGpsDevicePortConfig (data) {
  const req = {
    deviceId: data.deviceId,
    itemName: data.itemName,
    portName: data.portName,
    value: data.value,
    configType: data.configType
  }
  return request({
    url: 'gps/device/portConfig/insertGpsDevicePortConfig',
    method: 'post',
    data: req
  })
}
