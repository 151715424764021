<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">{{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.sysDept')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.deptList')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-form
        :model="queryInfo"
        label-width="140px"
        :inline="true"
      >
        <el-form-item :label="$t('dept.deptName')">
          <el-input :placeholder="$t('deptPage.deptNamePlaceholder')"
                    v-model.trim="queryInfo.deptName"
                    clearable
                    @clear="getDeptList">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.customerName')">
          <el-input :placeholder="$t('customerPage.customerNamePlaceholder')"
                    v-model.trim="queryInfo.customerName"
                    clearable
                    @clear="getDeptList">
          </el-input>
        </el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="getDeptList">{{$t('common.search')}}</el-button>
      </el-form>
      <el-button type="primary" @click="addHandle">{{$t('common.add')}}</el-button>
      <!-- 用户列表区域 -->
      <el-table :data="deptList" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" :label="$t('common.sn')" width="60px;"></el-table-column>
        <el-table-column prop="deptId" label="deptId" v-if="false"></el-table-column>
        <el-table-column prop="customer.customerName" :label="$t('customer.customerName')" width="200px;"></el-table-column>
        <el-table-column prop="deptName" :label="$t('dept.deptName')" width="200px;"></el-table-column>
        <el-table-column prop="createTime" :label="$t('dept.createTime')" width="150px;"></el-table-column>
        <el-table-column :label="$t('common.operation')" width="100px;">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              v-if="scope.row.deptId !== selfDeptId"
              @click="removeDeptById(scope.row.deptId)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog :title="$t('deptPage.addTitle')" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addUserForm"
        ref="addUserFormRef"
        :rules="addUserFormRules"
        label-width="150px"
      >
        <el-form-item :label="$t('dept.deptName')" prop="deptName">
          <el-input v-model.trim="addUserForm.deptName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.customerName')" prop="customerId">
          <el-select
            v-model="addUserForm.customerId"
            :placeholder="$t('customerPage.customerNamePlaceholder')"
            size="small"
          >
            <el-option v-for="customer in customerList" :key="customer.customerId" :value="customer.customerId" :label="customer.customerName" > {{ customer.customerName }} </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addDept">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listDept, create, deleteDeptByDeptId } from '@/api/sysDept'
import { checkLogin } from '@/api/globalProcessErr'
import { listOptionalCustomer } from '@/api/customer'

export default {
  data () {
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        deptName: '',
        customerName: '',
        // 当前页数
        pageNum: 1,
        // 每页显示多少数据
        pageSize: 10
      },
      deptList: [],
      selfDeptId: undefined,
      customerList: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        deptName: ''
      }
    }
  },
  computed: {
    addUserFormRules () {
      return {
        deptName: [
          { required: true, message: this.$t('deptPage.rules.deptName.required'), trigger: 'blur' },
          {
            min: 4,
            max: 30,
            message: this.$t('deptPage.rules.deptName.length'),
            trigger: 'blur'
          }],
        customerId: [
          { required: true, message: this.$t('deptPage.rules.customerId.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.selfDeptId = parseInt(window.sessionStorage.getItem('selfDeptId'))
    this.getDeptList()
  },
  methods: {
    getDeptList () {
      listDept(this.queryInfo).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.deptList = res.data.data.list
        this.total = res.data.data.total
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getAllCustomer () {
      const _this = this
      listOptionalCustomer().then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.customerList = res.data.data
      }).catch(err => {
        return _this.$message.error(err.message)
      })
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getDeptList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getDeptList()
    },
    addHandle () {
      this.getAllCustomer()
      this.addDialogVisible = true
    },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addUserFormRef.resetFields()
    },
    // 添加用户
    addDept () {
      // 提交请求前，表单预验证
      const self = this
      const createData = self.addUserForm
      this.$refs.addUserFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return
        create(createData).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          self.$message.info(self.$t('common.createSuccess'))
          self.addDialogVisible = false
          self.getDeptList()
        })
      })
    },
    // 删除用户
    async removeDeptById (id) {
      const self = this
      const deptId = id
      const confirmResult = await this.$confirm(
        self.$t('common.deleteConfirm'),
        self.$t('common.notice'),
        {
          confirmButtonText: self.$t('common.confirm'),
          cancelButtonText: self.$t('common.cancel'),
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info(self.$t('common.canceled'))
      }
      deleteDeptByDeptId(deptId).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.$message.info(self.$t('common.deleteSuccess'))
        this.getDeptList()
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
