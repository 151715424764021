import request from '@/api/request'

/**
 * 创建比赛
 * @param {String} name 比赛名称
 * @param {Long} date 比赛日期
 * @param {Long} guildId 鸽会ID
 */
export function createCompetition (name, date, guildId) {
  const req = {
    action: 'gpsPigeonRaceAction',
    method: 'insert',
    data: {
      pigeonRaceName: name,
      pigeonRaceDate: date,
      pigeonCommunityId: guildId
    }
  }
  return returnDefaultRequest(req)
}

/**
 * 根据比赛id删除对应比赛
 * @param {Integer} raceId 比赛id
 */
export function deleteCompetition (raceId) {
  const req = {
    action: 'gpsPigeonRaceAction',
    method: 'del',
    data: {
      id: raceId
    }
  }
  return returnDefaultRequest(req)
}

/**
 * 根据传入的日期查询比赛
 * @param {Date} date dateFormat: yyyy-MM-DD，日期为空表示查询全部
 * @returns competitionList
 */
export function queryCompetitionList (date) {
  const req = {
    action: 'gpsPigeonRaceAction',
    method: 'queryRaceByuidList',
    parameter: date
  }
  return returnDefaultRequest(req)
}

/**
 * 更新设备参加的比赛
 * @param {String} imei 设备IMEI
 * @param {Integer} raceId 比赛ID,为空时表示将设备从所有比赛中摘除
 */
export function updateCompetitionDevice (imei, raceId) {
  const req = {
    action: 'deviceAction',
    method: 'updateGpsDevicepigeonRaceId',
    data: {
      deviceImei: imei,
      pigeonRaceId: raceId
    }
  }
  return returnDefaultRequest(req)
}

/**
 * 获取默认参数的axios请求方法
 * @param {JSON} requestJson
 * @returns
 */
function returnDefaultRequest (requestJson) {
  return request({
    url: '/MainServlet',
    method: 'post',
    data: requestJson
  })
}
