<template>
  <div class="page">
    <!-- 面包屑导航区 -->
    <el-breadcrumb class="noBoard" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">Main Page</el-breadcrumb-item>
      <el-breadcrumb-item>Gps User</el-breadcrumb-item>
      <el-breadcrumb-item>User List</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card class="tableArea">
      <!-- 搜索 添加 -->
      <el-form :inline="true" ref="queryInfoRef" :model="queryInfo" class="demo-form-inline">
        <el-form-item label="User Name">
          <!--          <el-input placeholder="Please input the content" v-model="queryInfo.query" clearable @clear="getUserList">
                      <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                    </el-input>-->
          <el-input v-model="queryInfo.userName" placeholder="Input User Name"></el-input>
        </el-form-item>
        <el-form-item label="Mobile">
          <el-input v-model="queryInfo.mobile" placeholder="Input User Mobile"></el-input>
        </el-form-item>
        <el-form-item label="Status">
          <el-select v-model="queryInfo.status"
                     clearable
                     size="small" placeholder="Select status">
            <el-option value="1" label="Normal"></el-option>
            <el-option value="0" label="Forbidden"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="getUserList">Search</el-button>
          <el-button type="warning" size="mini" @click="resetQuery">Reset</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <el-button type="primary" @click="addDialogVisible = true">Add User</el-button>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="userList" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" label="#"></el-table-column>
        <el-table-column prop="userId" label="User Id" v-if="false"></el-table-column>
        <el-table-column prop="userName" label="User Name" width="90px;"></el-table-column>
        <el-table-column prop="email" label="Email"></el-table-column>
        <el-table-column prop="mobile" label="Mobile" width="100px;"></el-table-column>
        <el-table-column prop="status" label="Status" :formatter="statusFormatter"></el-table-column>
        <el-table-column prop="nick" label="Nick"></el-table-column>
        <el-table-column prop="sex" label="Sex" width="80px;" :formatter="sexFormatter"></el-table-column>
        <el-table-column prop="country" label="Country"></el-table-column>
        <el-table-column prop="province" label="Province"></el-table-column>
        <el-table-column prop="city" label="City"></el-table-column>
        <el-table-column prop="longitude" label="Longitude" width="100px;"></el-table-column>
        <el-table-column prop="latitude" label="Latitude" width="100px;"></el-table-column>
        <el-table-column prop="gpsTime" label="Gps Time" width="100px;"></el-table-column>
        <el-table-column prop="lastLoginTime" label="Last Login" width="100px;"></el-table-column>
        <el-table-column prop="remark" label="Notes" width="100px;"></el-table-column>
        <!--        <el-table-column label="status">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.mg_state" @change="userStateChanged(scope.row)"></el-switch>
                  </template>
                </el-table-column>-->
        <el-table-column label="Operation" fixed="right" width="280px;">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="showEditDialog(scope.row.userId)"
            >Edit</el-button>
            <el-button
              type="warning"
              size="mini"
              @click="resetPassword(scope.row.userId)"
            >ResetPass</el-button>
            <el-button
              type="danger"
              size="mini"
              @click="removeUserById(scope.row.userId)"
            >Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <!-- 添加用户的对话框 -->
    <el-dialog title="Add Gps User" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addForm"
        ref="addFormRef"
        :rules="addFormRules"
        label-width="100px"
      >
        <el-form-item label="User Name" >
          <el-input v-model="addForm.userName"></el-input>
        </el-form-item>
<!--        <el-form-item label="Password" >
          <el-input type="password" v-model="addForm.password"></el-input>
        </el-form-item>-->
        <el-form-item label="Email" >
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Sex">
          <el-select
            v-model="addForm.sex"
            placeholder="Please select..." size="small" clearable>
            <el-option v-for="dic in sexDicList" :key="parseInt(dic.codeValue)" :value="parseInt(dic.codeValue)" :label="dic.codeName" > {{ dic.codeName }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Mobile"  >
          <el-input v-model="addForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="Nick">
          <el-input v-model="addForm.nick"></el-input>
        </el-form-item>
        <el-form-item label="Country">
          <el-input v-model="addForm.country"></el-input>
        </el-form-item>
        <el-form-item label="Province">
          <el-input v-model="addForm.province"></el-input>
        </el-form-item>
        <el-form-item label="City">
          <el-input v-model="addForm.city"></el-input>
        </el-form-item>
        <el-form-item label="Notes">
          <el-input v-model="addForm.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addUser">Confirm</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="修改用户信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="editForm"
        ref="editFormRef"
        :rules="editFormRules"
        label-width="120px"
      >
        <el-form-item label="User Id" v-if="false"  >
          <el-input v-model="editForm.userId" v-if="false"></el-input>
        </el-form-item>
        <el-form-item label="User Name"  >
          <el-input v-model="editForm.userName" ></el-input>
        </el-form-item>
        <el-form-item label="Email" >
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Mobile" >
          <el-input v-model="editForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="Nick">
          <el-input v-model="editForm.nick"></el-input>
        </el-form-item>
        <el-form-item label="Country">
          <el-input v-model="editForm.country"></el-input>
        </el-form-item>
        <el-form-item label="Province">
          <el-input v-model="editForm.province"></el-input>
        </el-form-item>
        <el-form-item label="City">
          <el-input v-model="editForm.city"></el-input>
        </el-form-item>
        <el-form-item label="Notes">
          <el-input v-model="editForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="Sex">
            <el-select
              v-model="editForm.sex"
              placeholder="Please select..." size="small" clearable>
              <el-option v-for="dic in sexDicList" :key="parseInt(dic.codeValue)" :value="parseInt(dic.codeValue)" :label="dic.codeName" > {{ dic.codeName }} </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Status">
          <el-select
            v-model="editForm.status"
            placeholder="Please select..."
            size="small"
          >
            <el-option v-for="dic in statusDicList"
                       :key="parseInt(dic.codeValue)"
                       :value="parseInt(dic.codeValue)"
                       :label="dic.codeName">
              {{ dic.codeName }}</el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="Password" >
          <el-input type="password" v-model="editForm.password"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="editUser">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// , createUser, updateUser, deleteUser
import { listGpsUser, createGpsUser, updateGpsUser, getGpsUser, deleteGpsUser, resetPassword } from '@/api/gpsUser'
import { listCodeByClass } from '@/api/code'
import { checkLogin } from '@/api/globalProcessErr'
// import { listAllDept } from '@/api/sysDept'

export default {
  data () {
    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback()
      }
      callback(new Error('Please enter legal email'))
    }
    // 自定义手机号规则
    var checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return callback()
      }
      // 返回一个错误提示
      callback(new Error('Please enter a legal mobile phone number'))
    }
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        userName: '',
        mobile: '',
        status: undefined,
        // 当前页数
        pageNum: 1,
        // 每页显示多少数据
        pageSize: 20
      },
      userList: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      statusDicList: [],
      sexDicList: [],
      // 用户添加
      addForm: {
        userName: '',
        password: '',
        mobile: '',
        sex: undefined,
        nick: '',
        country: '',
        province: '',
        city: '',
        remark: '',
        email: ''
      },
      // 用户添加表单验证规则
      addFormRules: {
        userName: [
          { required: true, message: 'Please input user name', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: 'User name length 6-18 characters',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: 'please input password', trigger: 'blur' },
          {
            min: 6,
            max: 18,
            message: 'Password length 6-18 characters',
            trigger: 'blur'
          }
        ],
        email: [
          { required: false, message: 'please input email', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: 'please input mobile', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
      // 修改用户
      editDialogVisible: false,
      editForm: {
        userId: '',
        userName: '',
        password: '',
        mobile: '',
        sex: undefined,
        nick: '',
        country: '',
        province: '',
        city: '',
        remark: '',
        email: ''
      },
      // 编辑用户表单验证
      editFormRules: {
        userName: [
          { required: true, message: 'Please input user name', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: 'User name length 6-18 characters',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: 'please input password', trigger: 'blur' },
          {
            min: 6,
            max: 18,
            message: 'Password length 6-18 characters',
            trigger: 'blur'
          }
        ],
        email: [
          { required: false, message: 'please input email', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ]
      },
      // 分配角色对话框
      setRoleDialogVisible: false,
      // 当前需要被分配角色的用户
      userInfo: {}
    }
  },
  created () {
    this.getUserList()
    this.getSexDicList()
    this.getStatusDicList()
  },
  methods: {
    getUserList () {
      listGpsUser(this.queryInfo).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.userList = res.data.data.list
        this.total = res.data.data.total
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    getStatusDicList () {
      const _this = this
      listCodeByClass('USER_STATUS').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        _this.statusDicList = res.data.data
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    getSexDicList () {
      listCodeByClass('USER_SEX').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.sexDicList = res.data.data
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    update (updateData) {
      updateGpsUser(updateData).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.$message.info('Update Success!')
        this.getUserList()
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    create () {
      const _this = this
      createGpsUser(_this.addForm).then(res => {
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.$message.info('Create Success!')
        _this.getUserList()
      }).catch(err => {
        return _this.$message.error(err)
      })
    },
    getEditUser (userId) {
      getGpsUser(userId).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.editForm = res.data.data
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    delete (userId) {
      deleteGpsUser(userId).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.$message.info('Delete Success!')
        this.getUserList()
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    resetPassword (userId) {
      resetPassword(userId).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.$message.info('Reset Password Success!')
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    statusFormatter (row, column) {
      let status = row.status + ''
      for (let i = 0; i < this.statusDicList.length; i++) {
        if (status === this.statusDicList[i].codeValue) {
          return this.statusDicList[i].codeName
        }
      }
    },
    sexFormatter (row, column) {
      let sex = row.sex === null ? '0' : (row.sex !== '' ? row.sex + '' : '0')
      for (let i = 0; i < this.sexDicList.length; i++) {
        if (sex === this.sexDicList[i].codeValue) {
          return this.sexDicList[i].codeName
        }
      }
    },
    getSexText (value) {
      if (value === 0) {
        return 'unknown'
      } else if (value === 1) {
        return 'man'
      } else {
        return 'woman'
      }
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      // console.log(newSize)
      this.queryInfo.pageNum = newSize
      this.getUserList()
    },
    // 监听 switch开关 状态改变
    // async userStateChanged (userInfo) {
    //   // console.log(userInfo)
    //   const { data: res } = await this.$http.put(
    //     `users/${userInfo.id}/state/${userInfo.mg_state}`
    //   )
    //   if (res.meta.status !== 200) {
    //     userInfo.mg_state = !userInfo.mg_state
    //     return this.$message.error('更新用户状态失败')
    //   }
    //   this.$message.success('更新用户状态成功！')
    // },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 添加用户
    addUser () {
      this.$refs.addFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        this.create()
        // 隐藏添加用户对话框
        this.addDialogVisible = false
      })
    },
    // 编辑用户信息
    async showEditDialog (id) {
      this.getEditUser(id)
      this.editDialogVisible = true
    },
    // 监听修改用户对话框的关闭事件
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    resetQuery () {
      this.$refs.queryInfoRef.resetFields()
      this.queryInfo.userName = ''
      this.queryInfo.mobile = ''
      this.queryInfo.status = undefined
      this.queryInfo.pageNum = 1
      this.queryInfo.pageSize = 20
      this.getUserList()
    },
    // 修改用户信息
    editUser () {
      // 提交请求前，表单预验证
      let updateData = this.editForm
      this.$refs.editFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        this.update(updateData)
        this.editDialogVisible = false
      })
    },
    // 删除用户
    async removeUserById (userId) {
      const confirmResult = await this.$confirm(
        'This operation will permanently delete the user. Do you want to continue?',
        'Notice',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info('canceled')
      }
      this.delete(userId)
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  .el-form {
    .el-form-item {
      font-size: xx-small;
      width: 250px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;

      .el-input {
        width: 150px;
      }

      .el-select {
        width: 150px;
      }
    }
  }
}

.el-table {
  .el-table-column {
  }
}

.tableArea {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 95% !important;
}

.page {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.noBoard {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

</style>
