<template>
  <div class="page">
    <el-breadcrumb class="noBoard" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">{{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/device/deviceList' }">{{$t('breadCrumb.deviceList')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.inOutConfig')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="tableArea">
      <el-button type="primary" @click="initAdd">{{$t('common.add')}}</el-button>
      <el-table :data="portConfigList" border stripe>
        <el-table-column type="index" :label="$t('common.sn')"></el-table-column>
        <el-table-column prop="configId" label="configId" v-if="false"></el-table-column>
        <el-table-column prop="deviceId" label="deviceId" v-if="false"></el-table-column>
        <el-table-column prop="deviceImei" :label="$t('device.deviceImei')" width="150px;" :formatter="deviceImeiFormatter"></el-table-column>
        <el-table-column prop="itemName" :label="$t('portConfigPage.itemName')" width="220px;" :formatter="itemNameFormatter"></el-table-column>
        <el-table-column prop="portName" :label="$t('portConfigPage.portName')" width="150px;"></el-table-column>
        <el-table-column prop="value" :label="$t('portConfigPage.value')" width="150px;"></el-table-column>
        <el-table-column prop="configType" :label="$t('portConfigPage.configType')" width="200px;" :formatter="configTypeFormatter"></el-table-column>
        <el-table-column :label="$t('common.operation')"  width="140px;">
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="removeConfig(scope.row.configId)"
            >{{$t('portConfigPage.option.remove')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :title="$t('portConfigPage.addFromTitle')" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <el-form
        :model="addForm"
        ref="addForm"
        :inline="true"
        :rules="addFormRules"
        label-width="140px"
        label-position="right"
        class="addForm"
      >
        <el-form-item label="Config Id" v-if="false">
          <el-input v-model="addForm.configId"></el-input>
        </el-form-item>
        <el-form-item label="Device Id" v-if="false">
          <el-input v-model="addForm.deviceId"></el-input>
        </el-form-item>
        <el-row>
        <el-form-item :label="$t('device.deviceImei')">
          <el-input v-model="deviceImei" disabled></el-input>
        </el-form-item>
        </el-row>
        <el-row>
        <el-form-item :label="$t('portConfigPage.itemName')" prop="controlItem">
          <el-select v-model="addForm.itemName"
                     size="small"
                     :placeholder="$t('portConfigPage.itemNamePlaceholder')"
                     @change="selectItemName">
            <el-option v-for="dic in itemNameDicList" :key="dic.codeValue" :value="dic.codeValue"
                       :label="dic.codeName"> {{ dic.codeName }}
            </el-option>
          </el-select>
        </el-form-item>
        </el-row>
        <el-row>
        <el-form-item :label="$t('portConfigPage.portName')" prop="portName">
          <el-select v-model="addForm.portName"
                     size="small" :placeholder="$t('portConfigPage.portNamePlaceholder')" @change="checkOutPut">
            <el-option v-for="dic in portNameDicList" :key="dic.codeValue" :value="dic.codeValue"
                       :label="dic.codeName"> {{ dic.codeName }}
            </el-option>
          </el-select>
        </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('portConfigPage.value')" >
            <el-input v-model.trim="addForm.value"></el-input>
<!--            <el-select v-model="addForm.action"
                       size="small" :placeholder="$t('portConfigPage.actionPlaceholder')" :disabled="!outPut">
              <el-option v-for="dic in actionDicList" :key="dic.codeValue" :value="dic.codeValue"
                         :label="dic.codeName"> {{ dic.codeName }}
              </el-option>
            </el-select>-->
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="$t('portConfigPage.configType')" >
            <el-select v-model="addForm.configType"
                       size="small" :placeholder="$t('portConfigPage.configTypePlaceholder')"
                       disabled="true">
              <el-option v-for="dic in configTypeDicList" :key="dic.codeValue" :value="dic.codeValue"
                         :label="dic.codeName"> {{ dic.codeName }}
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addConfigPort">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listCodeByClass } from '@/api/code'
import { checkLogin } from '@/api/globalProcessErr'
import { getGpsDeviceById } from '@/api/gpsDevice'
import { listGpsDevicePortConfigByDeviceId, deleteGpsDevicePortConfig, insertGpsDevicePortConfig } from '@/api/portConfig'

export default {
  name: 'PortConfig',
  data () {
    return {
      deviceId: undefined,
      deviceImei: '',
      portConfigList: [],
      outPut: undefined,
      addForm: {
        deviceId: undefined,
        deviceImei: '',
        itemName: '',
        portName: '',
        value: undefined,
        configType: undefined
      },
      itemNameDicList: [],
      portNameDicList: [],
      outputDicList: [],
      inputDicList: [],
      configTypeDicList: [],
      addDialogVisible: false
    }
  },
  computed: {
    addFormRules () {
      return {
        itemName: [
          { required: true, message: this.$t('portConfigPage.rules.itemName.required'), trigger: 'blur' }
        ],
        portName: [
          { required: true, message: this.$t('portConfigPage.rules.portName.required'), trigger: 'blur' }
        ],
        value: [
          { required: true, message: this.$t('portConfigPage.rules.value.required'), trigger: 'blur' }
        ],
        configType: [
          { required: true, message: this.$t('portConfigPage.rules.configType.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.deviceId = parseInt(window.sessionStorage.getItem('PortConfig:DeviceId'))
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.getItemNameDicList()
    this.getPortNameDicList()
    this.getConfigTypeDicList()
    this.getDevice()
  },
  mounted () {
    this.getPortConfigList()
  },
  methods: {
    getItemNameDicList () {
      const self = this
      listCodeByClass('PORT_CONFIG_ITEM').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.itemNameDicList = res.data.data
      }).catch(err => {
        self.$message.error(err.message)
      })
    },
    getConfigTypeDicList () {
      const self = this
      listCodeByClass('PORT_CONFIG_TYPE').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.configTypeDicList = res.data.data
      }).catch(err => {
        self.$message.error(err.message)
      })
    },
    getDevice () {
      const self = this
      getGpsDeviceById(this.deviceId).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.deviceImei = res.data.data.deviceImei
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    getPortNameDicList () {
      const self = this
      listCodeByClass('PORT_NAME').then(res => {
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        if (res.data.data.length > 0) {
          self.outputDicList = []
          self.inputDicList = []
          self.portNameDicList = []
          for (let i = 0; i < res.data.data.length; i++) {
            if (res.data.data[i].codeValue.indexOf('OUTPUT') !== -1) {
              self.outputDicList.push(res.data.data[i])
            } else {
              self.inputDicList.push(res.data.data[i])
            }
          }
        }
      }).catch(err => {
        self.$message.error(err.message)
      })
    },
    getPortConfigList () {
      const _this = this
      listGpsDevicePortConfigByDeviceId(this.deviceId).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.portConfigList = res.data.data
        if (_this.portConfigList.length > 0) {
          for (let i = 0; i < _this.portNameDicList.length; i++) {
            _this.portConfigList[i].deviceImei = _this.deviceImei
          }
        }
      }).catch(err => {
        return _this.$message.error(err.message)
      })
    },
    checkOutPut () {
      this.outPut = this.addForm.portName.indexOf('OUTPUT') !== -1
    },
    configTypeFormatter (row, column) {
      const configType = row.configType + ''
      for (let i = 0; i < this.configTypeDicList.length; i++) {
        if (this.configTypeDicList[i].codeValue === configType) {
          return this.configTypeDicList[i].codeName
        }
      }
    },
    itemNameFormatter (row, column) {
      const itemName = row.itemName + ''
      for (let i = 0; i < this.itemNameDicList.length; i++) {
        if (this.itemNameDicList[i].codeValue === itemName) {
          return this.itemNameDicList[i].codeName
        }
      }
    },
    deviceImeiFormatter (row, column) {
      return this.deviceImei
    },
    addDialogClosed () {
      this.$refs.addForm.resetFields()
    },
    selectItemName () {
      if (this.addForm.itemName === undefined || this.addForm.itemName === '') {
        this.addForm.portName = ''
        this.addForm.configType = undefined
        this.addForm.value = undefined
        return
      }
      for (let i = 0; i < this.itemNameDicList.length; i++) {
        if (this.addForm.itemName === this.itemNameDicList[i].codeValue) {
          this.addForm.portName = ''
          this.addForm.value = undefined
          if (this.itemNameDicList[i].codeDesc.indexOf('INPUT') !== -1) {
            this.portNameDicList = this.inputDicList
            this.addForm.configType = '1'
          } else {
            this.portNameDicList = this.outputDicList
            this.addForm.configType = '2'
          }
          return
        }
      }
    },
    initAdd () {
      this.addForm.deviceId = this.deviceId
      this.addForm.itemName = undefined
      this.addForm.portName = undefined
      this.addDialogVisible = true
    },
    checkData () {
      if (this.addForm.itemName === undefined || this.addForm.portName === undefined) {
        return false
      }
      for (let i = 0; i < this.itemNameDicList.length; i++) {
        if (this.addForm.itemName === this.itemNameDicList[i].codeValue) {
          let dataIsInput = this.addForm.portName.indexOf('INPUT') !== -1
          let configIsInput = this.itemNameDicList[i].codeDesc.indexOf('INPUT') !== -1
          if (dataIsInput === configIsInput) {
            return true
          } else {
            return false
          }
        }
      }
      return false
    },
    addConfigPort () {
      const req = {
        deviceId: this.deviceId,
        itemName: this.addForm.itemName,
        portName: this.addForm.portName,
        value: this.addForm.value,
        configType: this.addForm.configType
      }

      const _this = this
      if (!this.checkData()) {
        return this.$message.error(_this.$t('portConfigPage.msg.checkData'))
      }

      insertGpsDevicePortConfig(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.$message.info(_this.$t('common.createSuccess'))
        _this.getPortConfigList()
        _this.addDialogVisible = false
      }).catch(err => {
        return _this.$message.error(err.message)
      })
    },
    async removeConfig (configId) {
      const _this = this
      const confirmResult = await this.$confirm(
        _this.$t('common.deleteConfirm'),
        _this.$t('common.notice'),
        {
          confirmButtonText: _this.$t('common.notice'),
          cancelButtonText: _this.$t('common.notice'),
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info(_this.$t('common.canceled'))
      }
      deleteGpsDevicePortConfig(configId).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.$message.info(_this.$t('common.deleteSuccess'))
        _this.getPortConfigList()
      }).catch(err => {
        _this.$message.error(err.message)
      })
    }
  }
}
</script>

<style scoped>
</style>
