<template>
  <div class="tracking">
    <GmapMap
      ref="mapRef"
      map-type-id="terrain"
      v-if="currentMap === 'google'"
      :center="{lat:25.037798, lng:121.565170}"
      :zoom="13"
      style="width: 100%; height: 100%"
      :options="{
          zoomControl: true,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          disableDefaultUI: false
        }"></GmapMap>
    <baidu-map v-if="currentMap === 'baidu'" class="baidu-map" :center="center" :zoom="13" @ready="handleBaidu"
               :scroll-wheel-zoom='true'>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"/>
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"/>
    </baidu-map>
    <div class="timeClass">{{$t('welcome.page.main.refreshPre') + second + $t('welcome.page.main.refreshNext')}}</div>
  </div>
</template>

<script>
import { getGpsDeviceByImei } from '@/api/gpsDevice'
import { checkLogin } from '@/api/globalProcessErr'

import { initMap, makePath, makeMarker } from '@/api/map'

export default {
  name: 'tracking',
  data () {
    return {
      deviceImei: '',
      center: {
        lat: 22.5711440000,
        lng: 113.8687300000
      },
      device: undefined,
      mapInstance: undefined,
      devicePath: [],
      prePath: undefined,
      currPath: undefined,
      currentMap: 'baidu',
      second: 15,
      deviceMark: {
        deviceImei: '',
        marker: undefined,
        infoWindow: undefined
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.deviceImei = window.sessionStorage.getItem('trackingDeviceImei') || ''
    this.checkLang()
    this.getGpsDevice()
  },
  mounted () {
    const _this = this
    setTimeout(function () {
      _this.initMapPage()
      if (_this.device === undefined) {
        _this.getGpsDevice()
      }
      makeMarker(_this.mapInstance, _this.device)
    }, 500)
    setTimeout(function () {
      makeMarker(_this.mapInstance)
    }, 800)
    _this.interval()
  },
  methods: {
    interval () {
      const _this = this
      _this.second = 15
      setInterval(function () {
        if (_this.second > 0) {
          _this.second--
        } else {
          setTimeout(function () {
            _this.getGpsDevice()
            makeMarker(_this.mapInstance, _this.device)
            // _this.makeStep()
          }, 600)
          _this.second = 15
        }
      }, 1000)
    },
    checkLang () {
      if (this.$i18n.locale === 'zh-CN') {
        this.currentMap = 'baidu'
      } else {
        this.currentMap = 'google'
      }
    },
    handleBaidu ({ BMap, map }) {
      this.mapInstance = {
        mapType: 'baidu',
        map: map,
        bMap: BMap,
        // map: window.$BAIDU$._instances.TANGRAM__1,
        // bMap: window.BMap,
        markList: [],
        currentOpenInfoWindow: undefined,
        contentFunc: this.makeContent,
        currentPoly: undefined,
        polyList: [],
        haveBtn: false
      }
    },
    initMapPage () {
      if (this.currentMap === 'google') {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'google',
            map: this.$refs.mapRef.$mapObject,
            mapRef: this.$refs.mapRef,
            googleMaps: window.google.maps,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false
          }
        }
      } else {
        if (this.mapInstance === undefined) {
          this.mapInstance = {
            mapType: 'baidu',
            // map: map,
            // bMap: BMap,
            map: window.$BAIDU$._instances.TANGRAM__1,
            bMap: window.BMap,
            markList: [],
            currentOpenInfoWindow: undefined,
            contentFunc: this.makeContent,
            currentPoly: undefined,
            polyList: [],
            haveBtn: false
          }
        }
      }
      initMap(this.mapInstance, this.center, 13)
    },
    makeContent (device, haveBtn) {
      const self = this
      let speed = 0
      if (device.odometer && device.duration && device.duration !== 0) {
        speed = device.odometer / device.duration
      }
      let averageSpeed = 0
      if (device.accumulateOdometer && device.accumulateDuration && device.accumulateDuration !== 0) {
        averageSpeed = device.accumulateOdometer / device.accumulateDuration
      }
      return function () {
        let content = '<style> .deviceInfo { font-size: 10px; margin: 0; padding: 0;} ul li { display: flex; flex-flow: row nowrap; justify-content: center; align-items: center; } ul li div { width: 200px; } .centerDiv{ width: 100%; display: flex; justify-content: space-around; margin-top: 4px; } .centerDiv button { font-size: 12px; width: 60px; border-radius: 10px;} </style>'
        content += '<ul class="deviceInfo">'
        content += '<li> <div> <b>' + self.$t('device.deviceImei') + ':</b> <span>' + device.deviceImei + '</span> </div>'
        content += '<div> <b>' + self.$t('device.nickname') + ':</b> <span>' + device.deviceName + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.lastDeviceVol') + ':</b> <span>' + (device.lastDeviceVol || 0) + '%' + '</span> </div>'
        content += '<div> <b>' + self.$t('device.version') + ':</b> <span>' + (device.version || '') + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.positioningTime') + ':</b> <span>' + device.lastGpsTime + '</span> </div>'
        content += '<div> <b>' + self.$t('device.uploadTime') + ':</b> <span>' + device.lastLocationTime + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.lastLatitude') + ':</b> <span>' + device.lastLatitude + '</span> </div>'
        content += '<div> <b>' + self.$t('device.lastLongitude') + ':</b> <span>' + device.lastLongitude + '</span> </div> </li>'
        // content += '<li> <div> <b>' + self.$t('device.weather') + ':</b> <span>' + device.weather + '</span> </div>'
        // content += '<div> <b>' + self.$t('device.lastPositionDesc') + ':</b> <span>' + device.lastPositionDesc + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.distanceFromPreviousPoint') + ':</b> <span>' + (device.odometer || 0) + self.$t('unit.meter') + '</span> </div>'
        content += '<div> <b>' + self.$t('device.timeFromPreviousPoint') + ':</b> <span>' + (device.duration || 0) + self.$t('unit.second') + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('unit.speedPerMinute') + ':</b> <span>' + (speed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
        content += '<div> <b>' + self.$t('unit.speedPerHour') + ':</b> <span>' + (speed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('unit.averageSpeedPerMinute') + ':</b> <span>' + (averageSpeed * 60).toFixed(4) + self.$t('unit.meterPerMinute') + '</span> </div>'
        content += '<div> <b>' + self.$t('unit.averageSpeedPerHour') + ':</b> <span>' + (averageSpeed * 3.6).toFixed(4) + self.$t('unit.kilometerPerHour') + '</span> </div> </li>'
        content += '<li> <div> <b>' + self.$t('device.altitude') + ':</b> <span>' + (device.altitude || 0) + self.$t('unit.meter') + '</span> </div>'
        content += '<div> <b>' + self.$t('device.locationPoints') + ':</b> <span>' + (device.pointIndex || '') + '</span> </div> </li>'
        if (haveBtn) {
          content += '<li> <div class="centerDiv">'
          content += '<button onclick="realTimeTrack(\'' + device.deviceImei + '\')"" type="button"> ' + self.$t('devicePage.realTimeTrack') + ' </button>'
          content += '<button onclick="historicalTrack(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.historicalTrack') + '  </button>'
          content += '<button onclick="sendCommand(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.sendCommand') + '  </button>'
          content += '<button onclick="getDeviceInfo(\'' + device.deviceImei + '\')" type="button">  ' + self.$t('devicePage.getDeviceInfo') + ' </button>'
          content += '</div> </li>'
        }
        content += '</ul>'
        return content
      }
    },
    motionStatusFormatter (row, column) {
      if (row.lastMotionStatus === 1) {
        return 'motion'
      } else if (row.lastMotionStatus === 0) {
        return 'static'
      } else {
        return ''
      }
    },
    getGpsDevice () {
      const self = this
      getGpsDeviceByImei(this.deviceImei).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.device = res.data.data
        makeMarker(self.mapInstance, self.device)
        const path = {
          deviceImei: res.data.data.deviceImei,
          lng: res.data.data.lastLongitude,
          lat: res.data.data.lastLatitude,
          heading: res.data.data.heading
        }
        if (path.lng && path.lat) {
          self.center = {
            lat: path.lat,
            lng: path.lng
          }
        }

        if (self.currPath === null) {
          self.currPath = path
        } else {
          self.prePath = self.currPath
          self.currPath = path
        }
        if (self.currPath && self.prePath) {
          self.makeStep()
        }
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    makeStep () {
      this.devicePath = [
        this.prePath,
        this.currPath
      ]
      makePath(this.mapInstance, this.devicePath)
    }
  }
}
</script>

<style scoped>
.tracking {
  width: calc(100% - 10px);
  height: 100%;
  margin-left: 5px;
}

.baidu-map {
  width: 99%;
  height: 99%;
}

.timeClass {
  font-weight: bold;
  color: black;
  background: #dddddd;
  position: absolute;
  text-align: center;
  z-index: 8;
  border-radius: 5px;
  left: 10px;
  top: 10px;
}

</style>
