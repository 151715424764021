<template>
  <div class="page">
    <!-- 面包屑导航区 -->
    <el-breadcrumb class="noBoard" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">{{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.gpsDevice')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.userDeviceBind')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card class="tableArea">
      <!-- 搜索 添加 -->
      <el-form :inline="true" ref="queryInfoRef" :model="queryInfo" class="demo-form-inline">
        <el-form-item :label="$t('user.userName')">
          <!--          <el-input placeholder="Please input the content" v-model="queryInfo.query" clearable @clear="getUserList">
                      <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                    </el-input>-->
          <el-input v-model="queryInfo.userName" :placeholder="$t('userPage.userNamePlaceholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.deviceImei')">
          <el-input v-model="queryInfo.deviceImei" :placeholder="$t('devicePage.deviceImeiPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getBindList">{{$t('common.search')}}</el-button>
          <el-button type="warning" @click="resetQuery">{{$t('common.reset')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <el-button type="primary" @click="addDialogVisible = true">{{$t('common.add')}}</el-button>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="userBindDeviceList" border stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
        <el-table-column type="index" label="SN."></el-table-column>
        <el-table-column type="bindId" label="bindId" v-if="false"></el-table-column>
        <el-table-column type="userId" label="userId" v-if="false"></el-table-column>
        <el-table-column type="deviceId" label="deviceId" v-if="false"></el-table-column>
        <el-table-column prop="userName" :label="$t('user.userName')" width="90px;"></el-table-column>
        <el-table-column prop="userMobile" :label="$t('user.mobile')" width="100px;"></el-table-column>
        <el-table-column prop="deviceImei" :label="$t('device.deviceImei')"  width="110px;"></el-table-column>
        <el-table-column prop="deviceName" :label="$t('device.deviceName')" width="110px;"></el-table-column>
        <el-table-column prop="deviceBindMobile" :label="$t('device.bindMobile')" width="150px;" ></el-table-column>
        <el-table-column prop="mainControl" :label="$t('userBind.mainControl')" width="120px;" :formatter="mainControlFormatter"></el-table-column>
        <el-table-column prop="createTime" :label="$t('userBind.createTime')" width="140px;"></el-table-column>
        <el-table-column prop="remark" :label="$t('userBind.remark')"  width="150px;"></el-table-column>
        <!--        <el-table-column label="status">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.mg_state" @change="userStateChanged(scope.row)"></el-switch>
                  </template>
                </el-table-column>-->
        <el-table-column :label="$t('common.operation')" width="100px;">
          <template slot-scope="scope">
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              @click="removeById(scope.row.bindId)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <!-- 添加用户的对话框 -->
    <el-dialog :title="$t('userBind.addFromTitle')" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addForm"
        ref="addFormRef"
        label-width="120px"
      >
        <el-form-item :label="$t('user.userName')" >
          <el-input :prefix-icon="userCheckIcon" v-model="addForm.userName"  @change="setUser(addForm.userName)" ></el-input>
        </el-form-item>
        <el-form-item :label="$t('device.deviceImei')" >
          <el-input :prefix-icon="deviceCheckIcon" v-model="addForm.deviceImei" @change="setDevice(addForm.deviceImei)"></el-input>
        </el-form-item>
        <el-form-item :label="$t('userBind.mainControl')" >
          <el-select v-model="addForm.mainControl"
                     clearable
                     size="small" :placeholder="$t('userBind.mainControlPlaceholder')">
            <el-option value="1" label="True">True</el-option>
            <el-option value="0" label="False">False</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="userId" v-if="false">
          <el-input v-model="addForm.userId" v-if="false"></el-input>
        </el-form-item>
        <el-form-item label="Device Id" v-if="false">
          <el-input v-model="addForm.deviceId" v-if="false"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addBind">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkLogin } from '@/api/globalProcessErr'
import { getGpsDeviceByImei } from '@/api/gpsDevice'
import { listUserBindDevice, createUserBindDevice, deleteUserBindDevice } from '@/api/userBindDevice'
import { getUserById } from '@/api/sysUser'

export default {
  data () {
    return {
      // 获取用户列表查询参数对象
      queryInfo: {
        userName: '',
        deviceImei: '',
        // 当前页数
        pageNum: 1,
        // 每页显示多少数据
        pageSize: 20
      },
      userBindDeviceList: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addForm: {
        deviceId: undefined,
        userId: '',
        deviceImei: '',
        userName: '',
        mainControl: undefined
      },
      editDialogVisible: false,
      editForm: {
        bindId: undefined,
        deviceId: undefined,
        userId: '',
        deviceImei: '',
        userName: ''
      },
      userCheckIcon: '',
      deviceCheckIcon: ''
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.getBindList()
  },
  methods: {
    getBindList () {
      listUserBindDevice(this.queryInfo).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.userBindDeviceList = res.data.data.list
        this.total = res.data.data.total
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    create (bindData) {
      const self = this
      createUserBindDevice(bindData).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        } else {
          this.getBindList()
          return this.$message.info(self.$t('common.createSuccess'))
        }
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    async removeById (bindId) {
      const self = this
      const confirmResult = await this.$confirm(
        self.$t('common.deleteConfirm'),
        self.$t('common.notice'),
        {
          confirmButtonText: self.$t('common.confirm'),
          cancelButtonText: self.$t('common.cancel'),
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info(self.$t('common.canceled'))
      }
      const delReq = {
        bindId: bindId
      }
      deleteUserBindDevice(delReq).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        } else {
          this.getBindList()
          return this.$message.info(self.$t('common.deleteSuccess'))
        }
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    setUser (userName) {
      const self = this
      if (userName === '') {
        return this.$message.error(self.$t('userBind.msg.userName'))
      }
      getUserById(userName).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          this.addForm.userId = undefined
          this.addForm.userName = ''
          this.userCheckIcon = 'el-icon-close'
          return this.$message.error(res.data.msg)
        } else {
          this.addForm.userId = res.data.data.userId
          this.userCheckIcon = 'el-icon-check'
        }
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    setDevice (deviceImei) {
      const self = this
      if (deviceImei === '') {
        return this.$message.error(self.$t('userBind.msg.userName'))
      }
      getGpsDeviceByImei(deviceImei).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          this.addForm.deviceId = undefined
          this.addForm.deviceImei = ''
          this.deviceCheckIcon = 'el-icon-close'
          return this.$message.error(res.data.msg)
        } else {
          this.addForm.deviceId = res.data.data.deviceId
          this.deviceCheckIcon = 'el-icon-check'
        }
      }).catch(err => {
        return this.$message.error(err)
      })
    },
    statusFormatter (row, column) {
      if (row.status === 1) {
        return 'Normal'
      } else {
        return 'Forbidden'
      }
    },
    mainControlFormatter (row, column) {
      if (row.status === 1) {
        return 'True'
      } else {
        return 'False'
      }
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getBindList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pageNum = newSize
      this.getBindList()
    },
    // 监听 添加对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
      this.addForm.deviceImei = ''
      this.addForm.userName = ''
      this.addForm.deviceId = ''
      this.addForm.userId = ''
      this.addForm.mainControl = undefined
      this.userCheckIcon = ''
      this.deviceCheckIcon = ''
    },
    // 添加用户
    addBind () {
      this.$refs.addFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return
        this.create(this.addForm)
        // 隐藏添加用户对话框
        this.addDialogVisible = false
      })
    },
    resetQuery () {
      // this.$refs['queryInfoRef'].resetFields()
      this.queryInfo.deviceImei = ''
      this.queryInfo.userName = ''
      this.getBindList()
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  .el-form {
    .el-form-item {
      font-size: xx-small;
      width: 250px;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;

      .el-input {
        width: 150px;
      }

      .el-select {
        width: 150px;
      }
    }
  }
}

.el-table {
  .el-table-column {
  }
}

.tableArea {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 95% !important;
}

.page {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.noBoard {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

</style>
