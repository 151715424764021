<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">{{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.sysManage')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.codeManage')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索 添加 -->
      <el-form :inline="true" :model="queryParams" class="demo-form-inline">
        <el-form-item :label="$t('code.codeClass')">
          <el-input v-model.trim="queryParams.codeClass" :placeholder="$t('codePage.codeClassPlaceholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('code.codeName')">
          <el-input v-model.trim="queryParams.codeName" :placeholder="$t('codePage.codeNamePlaceholder')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getCodeList">{{$t('common.search')}}</el-button>
          <el-button type="warning" @click="resetSearch">{{$t('common.reset')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <el-button type="primary" @click="addDialogVisible = true">{{$t('common.add')}}</el-button>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="codeList" border stripe>
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="index" :label="$t('common.sn')"></el-table-column>
        <el-table-column prop="codeClass" :label="$t('code.codeClass')"></el-table-column>
        <el-table-column prop="codeName" :label="$t('code.codeName')"></el-table-column>
        <el-table-column prop="codeValue" :label="$t('code.codeValue')"></el-table-column>
        <el-table-column prop="codeDesc" :label="$t('code.codeDesc')"></el-table-column>
        <el-table-column :label="$t('common.operation')">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="showEditDialog(scope.row.codeClass, scope.row.codeValue)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              @click="removeCode(scope.row.codeClass, scope.row.codeValue)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog title="Add Code" :visible.sync="addDialogVisible" width="50%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addForm"
        ref="addFormRef"
        :rules="addFormRules"
        label-width="120px"
      >
        <el-form-item :label="$t('code.codeClass')" prop="codeClass">
          <el-input v-model.trim="addForm.codeClass"></el-input>
        </el-form-item>
        <el-form-item :label="$t('code.codeName')" prop="codeName">
          <el-input v-model.trim="addForm.codeName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('code.codeValue')" prop="codeValue">
          <el-input v-model.trim="addForm.codeValue"></el-input>
        </el-form-item>
        <el-form-item :label="$t('code.codeDesc')" prop="codeDesc">
          <el-input v-model.trim="addForm.codeDesc"></el-input>
        </el-form-item>
      <span  class="dialog-footer myButton">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addCode" >{{$t('common.confirm')}}</el-button>
      </span>
      </el-form>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="Edit Code"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="editForm"
        ref="editFormRef"
        :rules="editFormRules"
        label-width="120px"
      >
        <el-form-item :label="$t('code.codeClass')" prop="codeClass">
          <el-input v-model="editForm.codeClass" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('code.codeName')" prop="codeName">
          <el-input v-model="editForm.codeName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('code.codeValue')" prop="codeValue">
          <el-input v-model="editForm.codeValue"></el-input>
        </el-form-item>
        <el-form-item :label="$t('code.codeDesc')" prop="codeDesc">
          <el-input v-model="editForm.codeDesc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="editCode">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>

    <!-- 分配角色对话框 -->
<!--    <el-dialog title="分配角色" :visible.sync="setRoleDialogVisible" width="50%" @close="setRoleDialogClosed">
      <div>
        <p>当前用户：{{userInfo.username}}</p>
        <p>当前角色：{{userInfo.role_name}}</p>
        <p>
          分配角色：
          <el-select
            v-model="selectRoleId"
            filterable
            allow-create
            default-first-option
            placeholder="请选择文章标签"
          >
            <el-option
              v-for="item in rolesLsit"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            ></el-option>
          </el-select>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setRoleDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="saveRoleInfo">Confirm</el-button>
      </span>
    </el-dialog>-->
  </div>
</template>

<script>
import { listCode, deleteCode, insertCode, updateCode, getCodeByClassAndValue } from '@/api/code'
import { checkLogin } from '@/api/globalProcessErr'
// import { AxiosRequestConfig } from 'axios'

export default {
  data () {
    return {
      // 获取用户列表查询参数对象
      queryParams: {
        codeClass: '',
        codeName: '',
        // 当前页数
        pageNum: 1,
        // 每页显示多少数据
        pageSize: 20
      },
      codeList: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addForm: {
        codeDesc: '',
        codeClass: '',
        codeName: '',
        codeValue: ''
      },
      // 用户添加表单验证规则
      // 修改用户
      editDialogVisible: false,
      editForm: {
        codeDesc: '',
        codeClass: '',
        codeName: '',
        codeValue: ''
      }
    }
  },
  computed: {
    addFormRules () {
      return {
        codeClass: [
          { required: true, message: this.$t('codePage.rules.codeClass.required'), trigger: 'blur' },
          {
            min: 3,
            max: 30,
            message: this.$t('codePage.rules.codeClass.length'),
            trigger: 'blur'
          }
        ],
        codeName: [
          { required: true, message: this.$t('codePage.rules.codeName.required'), trigger: 'blur' }
        ],
        codeValue: [
          { required: true, message: this.$t('codePage.rules.codeValue.required'), trigger: 'blur' }
        ]
      }
    },
    editFormRules () {
      return {
        codeName: [
          { required: true, message: this.$t('codePage.rules.codeName.required'), trigger: 'blur' }
        ],
        codeValue: [
          { required: true, message: this.$t('codePage.rules.codeValue.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.getCodeList()
  },
  methods: {
    getCodeList () {
      const req = this.queryParams
      listCode(req).then(res => {
        checkLogin(res)
        const code = res.data.code
        if (code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.codeList = res.data.data.list
        this.total = res.data.data.total
      }).catch(err => {
        this.$message.error(err.message)
      })
    },
    insert (data) {
      const self = this
      insertCode(data).then(res => {
        checkLogin(res)
        const code = res.data.code
        if (code !== 0) {
          return self.$message.error(res.data.msg)
        } else {
          self.$message.success(self.$t('common.createSuccess'))
        }
        self.getCodeList()
      }).catch(err => {
        self.$message.error(err.message)
      })
    },
    delCode (codeClass, codeValue) {
      const self = this
      deleteCode(codeClass, codeValue).then(res => {
        checkLogin(res)
        const code = res.data.code
        if (code !== 0) {
          return self.$message.error(res.data.msg)
        } else {
          self.$message.success(self.$t('common.deleteSuccess'))
        }
        self.getCodeList()
      }).catch(err => {
        self.$message.error(err.message)
      })
    },
    getSingleCode (codeClass, codeValue) {
      getCodeByClassAndValue(codeClass, codeValue).then(res => {
        checkLogin(res)
        const code = res.data.code
        if (code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.editForm = res.data.data
      }).catch(err => {
        this.$message.error(err.message)
      })
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize
      this.getCodeList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryParams.pageNum = newSize
      this.getCodeList()
    },
    statusFormat (row, column) {
      if (row.status === 1) {
        return 'Enable'
      } else {
        return 'Disable'
      }
    },
    resetSearch () {
      this.queryParams.codeName = ''
      this.queryParams.codeClass = ''
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 20
      this.getCodeList()
    },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    // 添加用户
    addCode () {
      // 提交请求前，表单预验证
      let $this = this
      this.$refs.addFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return
        $this.insert($this.addForm)
        // 隐藏添加用户对话框
        this.addDialogVisible = false
      })
    },
    // 编辑用户信息
    showEditDialog (codeClass, codeValue) {
      this.getSingleCode(codeClass, codeValue)
      this.editDialogVisible = true
    },
    // 监听修改用户对话框的关闭事件
    editDialogClosed () {
      this.$refs.editFormRef.resetFields()
    },
    // 修改用户信息
    editCode () {
      // 提交请求前，表单预验证
      const editData = this.editForm
      const self = this
      this.$refs.editFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return
        updateCode(editData).then(res => {
          checkLogin(res)
          const code = res.data.code
          if (code !== 0) {
            return self.$message.error(res.data.msg)
          } else {
            self.$message.success(self.$t('common.deleteSuccess'))
          }
          self.getCodeList()
        }).catch(err => {
          self.$message.error(err.message)
        })
        // 隐藏添加用户对话框
        this.editDialogVisible = false
      })
    },
    // 删除用户
    async removeCode (codeClass, codeValue) {
      const self = this
      const confirmResult = await this.$confirm(
        self.$t('common.deleteConfirm'),
        self.$t('common.deleteConfirm'),
        {
          confirmButtonText: self.$t('common.confirm'),
          cancelButtonText: self.$t('common.cancel'),
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info(self.$t('common.canceled'))
      }
      this.delCode(codeClass, codeValue)
    }
  }
}
</script>

<style lang="less" scoped>
.myButton {

}
</style>
