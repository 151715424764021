var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tracking"},[(_vm.currentMap === 'google')?_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"100%"},attrs:{"map-type-id":"terrain","center":{lat:25.037798, lng:121.565170},"zoom":13,"options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: false
      }}}):_vm._e(),(_vm.currentMap === 'baidu')?_c('baidu-map',{staticClass:"baidu-map",attrs:{"center":_vm.center,"zoom":13,"scroll-wheel-zoom":true},on:{"ready":_vm.handleBaidu}},[_c('bm-navigation',{attrs:{"anchor":"BMAP_ANCHOR_TOP_RIGHT"}}),_c('bm-map-type',{attrs:{"map-types":['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP'],"anchor":"BMAP_ANCHOR_TOP_LEFT"}})],1):_vm._e(),_c('div',{staticClass:"timeClass"},[_vm._v(_vm._s(_vm.$t('welcome.page.main.refreshPre') + _vm.second + _vm.$t('welcome.page.main.refreshNext')))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }