<template>
  <div class="page">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }"> {{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.sysUser')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.userList')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card >
      <!-- 搜索 添加 -->
      <el-form :inline="true" :model="queryParams" class="demo-form-inline">
        <el-form-item :label="$t('user.userName')">
          <el-input v-model="queryParams.userName" :placeholder="$t('userPage.userNamePlaceholder')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.status')">
          <el-select
            v-model="queryParams.status"
            :placeholder="$t('userPage.userStatusPlaceholder')"
            size="small"
          >
            <el-option v-for="dic in statusDicList"
                       :key="dic.codeValue"
                       :value="dic.codeValue"
                       :label="dic.codeName">
              {{ dic.codeName }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.mobile')">
          <el-input v-model="queryParams.mobile" :placeholder="$t('userPage.userMobilePlaceholder')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getUserList">{{$t('common.search')}}</el-button>
          <el-button type="warning" @click="resetSearch">{{$t('common.reset')}}</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <el-button type="primary" @click="initAdd">{{$t('common.add')}}</el-button>
<!--        <el-button type="warning" @click="editDialogVisible = true">Edit</el-button>-->
      </el-row>
<!--      <el-row :gutter="20">
        <el-col :span="6">
          <el-input placeholder="Please input the content" v-model="queryInfo.query" clearable @clear="getUserList">
            <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">Add User</el-button>
        </el-col>
      </el-row>-->
      <!-- 用户列表区域 -->
      <el-table :data="userList" border stripe >
        <!-- stripe: 斑马条纹
        border：边框-->
<!--        <el-table-column type="selection"></el-table-column>-->
        <el-table-column type="index" :label="$t('common.sn')" width="50px;"></el-table-column>
        <el-table-column prop="userId" :label="$t('user.userName')" v-if="false"></el-table-column>
        <el-table-column prop="customer.customerName" :label="$t('customer.customerName')" width="200px;"></el-table-column>
        <el-table-column prop="dept.deptName" :label="$t('dept.deptName')" width="180px;"></el-table-column>
        <el-table-column prop="userName" :label="$t('user.userName')" width="140px;"></el-table-column>
        <el-table-column prop="userRole" :label="$t('user.userRole')" width="140px;" ></el-table-column>
        <el-table-column prop="email" :label="$t('user.email')" width="140px;"></el-table-column>
        <el-table-column prop="mobile" :label="$t('user.mobile')" width="140px;"></el-table-column>
        <el-table-column prop="sex" :label="$t('user.sex')" width="140px;" :formatter="sexFormat"></el-table-column>
        <el-table-column prop="status" :label="$t('user.status')" :formatter="statusFormat" width="140px;"> </el-table-column>
        <el-table-column prop="expireDate" :label="$t('user.expireDate')" width="140px;"></el-table-column>
        <el-table-column prop="createTime" :label="$t('user.createTime')" width="140px;"></el-table-column>
<!--        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="userStateChanged(scope.row)"></el-switch>
          </template>
        </el-table-column>-->
        <el-table-column :label="$t('common.operation')"  width="140px;">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="mini"
              circle
              @click="showEditDialog(scope.row.userId)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              circle
              v-if="scope.row.userId !== selfUserId"
              @click="removeUserById(scope.row.userId)"
            ></el-button>
<!--            <el-tooltip
              class="item"
              effect="dark"
              content="角色分配"
              :enterable="false"
              placement="top"
            >
              <el-button
                type="warning"
                icon="el-icon-setting"
                size="mini"
                circle
                @click="showSetRole(scope.row)"
              ></el-button>
            </el-tooltip>-->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[20, 50, 100]"
        :page-size="queryParams.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>

    <!-- 添加用户的对话框 -->
    <el-dialog title="Add User" :visible.sync="addDialogVisible" width="40%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addUserForm"
        ref="addUserFormRef"
        :rules="addUserFormRules"
        label-width="150px"
        label-position="left"
      >
        <el-form-item :label="$t('user.userName')" prop="userName">
          <el-input v-model.trim="addUserForm.userName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.password')" prop="password">
          <el-input v-v-model.trim="addUserForm.password"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.customerName')" prop="customerId">
          <el-select
            v-model="addUserForm.customerId"
            :placeholder="$t('userPage.customerPlaceholder')"
            size="small"
            @change="selectCustomer(addUserForm.customerId)"
          >
            <el-option v-for="customer in customerList" :key="customer.customerId" :value="customer.customerId" :label="customer.customerName" > {{ customer.customerName }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dept.deptName')" prop="deptId">
          <el-select
            v-model="addUserForm.deptId"
            :placeholder="$t('userPage.deptPlaceholder')"
            size="small"
          >
            <el-option v-for="dept in deptList" :key="dept.deptId" :value="dept.deptId" :label="dept.deptName" > {{ dept.deptName }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.userRole')" prop="userRole" >
          <el-select
            v-model="addUserForm.userRole"
            :placeholder="$t('userPage.userRolePlaceholder')"
            size="small"
          >
            <el-option v-for="role in roleList"
                       :key="role"
                       :value="role"
                       :label="role">
              {{ role }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.email')" prop="email">
          <el-input v-model.trim="addUserForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.mobile')" >
          <el-input v-model.trim="addUserForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.sex')" prop="sex">
          <el-select
            v-model="addUserForm.sex"
            :placeholder="$t('userPage.sexPlaceholder')"
            size="small"
          >
            <el-option v-for="dic in sexDicList"
                       :key="parseInt(dic.codeValue)"
                       :value="parseInt(dic.codeValue)"
                       :label="dic.codeName">
              {{ dic.codeName }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.expireDate')" >
          <el-date-picker
            v-model="addUserForm.expireDate"
            align="right"
            type="date"
            value-format="yyyy-MM-dd"
            :format="this.$i18n.locale.includes('en')?'dd/MM/yyyy':'yyyy-MM-dd'"
            :placeholder="$t('userPage.expireDatePlaceholder')"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addUser">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>

    <!-- 修改用户的对话框 -->
    <el-dialog
      title="Edit user"
      :visible.sync="editDialogVisible"
      width="40%"
      @close="editDialogClosed"
    >
      <!-- 内容主体 -->
      <el-form
        :model="editUserForm"
        ref="editUserFormRef"
        :rules="editUserFormRules"
        label-width="150px"
        label-position="left"
      >
        <el-form-item label="User Id" v-if="false">
          <el-input v-model="editUserForm.userId" v-if="false"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.userName')" >
          <el-input v-model="editUserForm.userName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.email')" prop="email">
          <el-input v-model.trim="editUserForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.mobile')" prop="mobile">
          <el-input v-model.trim="editUserForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.sex')" >
          <el-select
            v-model="editUserForm.sex"
            :placeholder="$t('userPage.sexPlaceholder')" size="small" clearable>
            <el-option v-for="dic in sexDicList" :key="parseInt(dic.codeValue)" :value="parseInt(dic.codeValue)" :label="dic.codeName" > {{ dic.codeName }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.status')" >
          <el-select
            v-model="editUserForm.status"
            :placeholder="$t('userPage.statusPlaceholder')"
            size="small"
          >
            <el-option v-for="dic in statusDicList"
                       :key="parseInt(dic.codeValue)"
                       :value="parseInt(dic.codeValue)"
                       :label="dic.codeName">
              {{ dic.codeName }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dept.deptName')" >
          <el-input disabled v-model="editUserForm.dept.deptName"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.customerName')" prop="customerId">
          <el-select
            v-model="editUserForm.customerId"
            :placeholder="$t('userPage.customerPlaceholder')"
            size="small"
            disabled
          >
            <el-option v-for="customer in customerList" :key="customer.customerId" :value="customer.customerId" :label="customer.customerName" > {{ customer.customerName }} </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.expireDate')" >
          <el-date-picker
            v-model="editUserForm.expireDate"
            align="right"
            type="date"
            value-format="yyyy-MM-dd"
            :format="this.$i18n.locale.includes('en')?'dd/MM/yyyy':'yyyy-MM-dd'"
            :placeholder="$t('userPage.expireDatePlaceholder')"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="editUser">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { listUser, createUser, updateUser, deleteUser, getUserById, listRoleName } from '@/api/sysUser'
import { listDeptByCustomerId } from '@/api/sysDept'
import { checkLogin } from '@/api/globalProcessErr'
import { listCodeByClass, listOptionalUserType } from '@/api/code'
import { listOptionalCustomer } from '@/api/customer'
import { addMonth } from '@/api/dateFunc'
// import { AxiosRequestConfig } from 'axios'

export default {
  data () {
    // 自定义邮箱规则
    let checkEmail = (rule, value, callback) => {
      if (value === '') {
        return callback()
      }
      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback()
      }
      callback(new Error(this.$t('userPage.rules.email.legalEmail') + ''))
    }
    // 自定义手机号规则
    let checkMobile = (rule, value, callback) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return callback()
      }
      // 返回一个错误提示
      callback(new Error(this.$t('userPage.rules.mobile.legalMobile') + ''))
    }
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() <= Date.now()
        },
        shortcuts: [{
          text: this.$t('userPage.rules.expireDate.threeMonth'),
          onClick (picker) {
            const date = new Date()
            let newDate = addMonth(date, 3)
            picker.$emit('pick', newDate)
          }
        }, {
          text: this.$t('userPage.rules.expireDate.sixMonth'),
          onClick (picker) {
            const date = new Date()
            let newDate = addMonth(date, 6)
            picker.$emit('pick', newDate)
          }
        }, {
          text: this.$t('userPage.rules.expireDate.oneYear'),
          onClick (picker) {
            const date = new Date()
            let newDate = addMonth(date, 12)
            picker.$emit('pick', newDate)
          }
        }]
      },
      // 获取用户列表查询参数对象
      queryParams: {
        userName: '',
        status: undefined,
        mobile: '',
        // 当前页数
        pageNum: 1,
        // 每页显示多少数据
        pageSize: 20
      },
      userList: [],
      total: 0,
      statusDicList: [],
      sexDicList: [],
      roleList: [],
      userTypeDicList: [],
      optionalUserTypeList: [],
      checkEmail: checkEmail,
      checkMobile: checkMobile,
      // 添加用户对话框
      addDialogVisible: false,
      // 用户添加
      addUserForm: {
        userName: '',
        password: '',
        email: '',
        mobile: '',
        sex: undefined,
        deptId: undefined,
        dept: undefined,
        userRole: undefined,
        customerId: undefined,
        expireDate: ''
      },
      editDialogVisible: false,
      editUserForm: {
        userId: undefined,
        userName: '',
        password: '',
        email: '',
        mobile: '',
        sex: undefined,
        status: undefined,
        deptId: undefined,
        dept: {
          deptId: undefined,
          deptName: ''
        },
        userType: undefined,
        customerId: undefined,
        expireDate: ''
      },
      setRoleDialogVisible: false,
      // 当前需要被分配角色的用户
      userInfo: {},
      // 已选中的角色Id值
      selectRoleId: '',
      deptList: [],
      customerList: [],
      selfUserId: undefined
    }
  },
  computed: {
    addUserFormRules () {
      return {
        userName: [
          { required: true, message: this.$t('userPage.rules.userName.required'), trigger: 'blur' },
          {
            min: 4,
            max: 10,
            message: this.$t('userPage.rules.userName.length'),
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: this.$t('userPage.rules.password.required'), trigger: 'blur' },
          {
            min: 6,
            max: 18,
            message: this.$t('userPage.rules.password.length'),
            trigger: 'blur'
          }
        ],
        email: [
          { validator: this.checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('userPage.rules.mobile.required'), trigger: 'blur' },
          { validator: this.checkMobile, trigger: 'blur' }
        ],
        customerId: [
          { required: true, message: this.$t('userPage.rules.customerId.required'), trigger: 'blur' }
        ],
        userType: [
          { required: true, message: this.$t('userPage.rules.userType.required'), trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: this.$t('userPage.rules.deptId.required'), trigger: 'blur' }
        ],
        userRole: [
          { required: true, message: this.$t('userPage.rules.deptId.userRole'), trigger: 'blur' }
        ]
      }
    },
    editUserFormRules () {
      return {
        email: [
          { validator: this.checkEmail, trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: this.$t('userPage.rules.mobile.required'), trigger: 'blur' },
          { validator: this.checkMobile, trigger: 'blur' }
        ],
        customerId: [
          { required: true, message: this.$t('userPage.rules.customerId.required'), trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.selfUserId = parseInt(window.sessionStorage.getItem('userId'))
    this.getUserList()
    this.getSexDicList()
    this.getStatusDicList()
    this.getUserTypeDicList()
    this.getAllCustomer()
  },
  methods: {
    selectCustomer (customerId) {
      this.addUserForm.deptId = undefined
      listDeptByCustomerId(customerId).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.deptList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
      this.listRoleNameByCustomerId(customerId)
    },
    listRoleNameByCustomerId (customerId) {
      this.addUserForm.userType = ''
      const req = {
        customerId: customerId
      }
      const self = this
      listRoleName(req).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.roleList = res.data.data
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    getAllCustomer () {
      const _this = this
      listOptionalCustomer().then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.customerList = res.data.data
      }).catch(err => {
        return _this.$message.error(err.message)
      })
    },
    async getUserList () {
      const _this = this
      listUser(this.queryParams).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        _this.userList = res.data.data.list
        _this.total = res.data.data.total
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getStatusDicList () {
      const _this = this
      listCodeByClass('USER_STATUS').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        _this.statusDicList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getSexDicList () {
      listCodeByClass('USER_SEX').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.sexDicList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getUserTypeDicList () {
      listCodeByClass('USER_TYPE').then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.userTypeDicList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getOptionalUserTypeList () {
      listOptionalUserType().then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.optionalUserTypeList = res.data.data
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    resetSearch () {
      this.queryParams.mobile = ''
      this.queryParams.userName = ''
      this.queryParams.status = undefined
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 20
      this.getUserList()
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      // console.log(newSize)
      this.queryParams.pageSize = newSize
      this.getUserList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      // console.log(newSize)
      this.queryParams.pageNum = newSize
      this.getUserList()
    },
    statusFormat (row, column) {
      let status = row.status + ''
      for (let i = 0; i < this.statusDicList.length; i++) {
        if (status === this.statusDicList[i].codeValue) {
          return this.statusDicList[i].codeName
        }
      }
    },
    sexFormat (row, column) {
      let sex = row.sex === null ? '0' : (row.sex !== '' ? row.sex + '' : '0')
      for (let i = 0; i < this.sexDicList.length; i++) {
        if (sex === this.sexDicList[i].codeValue) {
          return this.sexDicList[i].codeName
        }
      }
    },
    // 监听 添加用户对话框的关闭事件
    addDialogClosed () {
      this.$refs.addUserFormRef.resetFields()
    },
    // 添加用户
    initAdd () {
      this.getSexDicList()
      // this.getOptionalUserTypeList()
      this.getAllCustomer()
      // this.getStatusDicList()
      this.addDialogVisible = true
    },
    addUser () {
      // 提交请求前，表单预验证
      let self = this
      this.$refs.addUserFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        createUser(self.addUserForm).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg)
          }
          self.$message.success(self.$t('common.createSuccess'))
          self.addDialogVisible = false
          self.getUserList()
        }).catch(err => {
          return this.$message.error(err.message)
        })
      })
    },
    // 编辑用户信息
    showEditDialog (id) {
      const _this = this
      getUserById(id).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return _this.$message.error(res.data.msg)
        }
        _this.editUserForm = res.data.data
        _this.editDialogVisible = true
      }).catch(err => {
        return _this.$message.error(err.message)
      })
      _this.getSexDicList()
      // this.getStatusDicList()
    },
    // 监听修改用户对话框的关闭事件
    editDialogClosed () {
      this.$refs.editUserFormRef.resetFields()
    },
    // 修改用户信息
    editUser () {
      // 提交请求前，表单预验证
      let self = this
      this.$refs.editUserFormRef.validate(async valid => {
        // console.log(valid)
        // 表单预校验失败
        if (!valid) return
        updateUser(self.editUserForm).then(res => {
          checkLogin(res)
          if (res.data.code !== 0) {
            return self.$message.error(res.data.msg)
          }
          self.editDialogVisible = false
          self.$message.success(self.$t('common.updateSuccess'))
          self.getUserList()
        }).catch(err => {
          return self.$message.error(err.message)
        })
      })
    },
    // 删除用户
    async removeUserById (id) {
      let self = this
      const confirmResult = await this.$confirm(
        self.$t('common.deleteConfirm'),
        self.$t('common.notice'),
        {
          confirmButtonText: self.$t('common.confirm'),
          cancelButtonText: self.$t('common.cancel'),
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.info(self.$t('common.canceled'))
      }
      deleteUser(id).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.$message.success(self.$t('common.deleteSuccess'))
        self.getUserList()
      }).catch(err => {
        return self.$message.error(err.message)
      })
    }
  }
}
</script>

<style lang="less" scoped>
</style>
