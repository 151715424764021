<template>
  <div class="page">
    <!-- 面包屑导航区 -->
    <!-- <el-breadcrumb class="noBoard" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">{{$t('breadCrumb.mainPage')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.gpsDevice')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('breadCrumb.deviceList')}}</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 卡片视图 -->
    <el-card class="tableArea">
      <!-- 搜索 添加 -->
      <el-form :inline="true" ref="queryInfoRef" :model="queryInfo" class="demo-form-inline">
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('device.deviceImei')">
              <el-input v-model.trim="queryInfo.deviceImei" :placeholder="$t('devicePage.deviceImeiPlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item :label="$t('device.nickname')">
              <el-input v-model.trim="queryInfo.deviceName" :placeholder="$t('devicePage.deviceNamePlaceholder')"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" @click="getDeviceList">{{$t('common.search')}}</el-button>
              <el-button type="warning" @click="resetQuery">{{$t('common.reset')}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-button type="primary" @click="initAdd">{{$t('common.add')}}</el-button>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table :data="deviceList" :border="true" stripe>
        <!-- stripe: 斑马条纹
        border：边框-->
<!--        <el-table-column type="selection" ></el-table-column>-->
        <el-table-column type="index" :label="$t('common.sn')" fixed></el-table-column>
        <el-table-column type="deviceId" label="deviceId" v-if="false"></el-table-column>
<!--        <el-table-column prop="organization" :label="$t('device.organization')" width="140px;"></el-table-column>-->
        <el-table-column prop="deviceImei" :label="$t('device.deviceImei')" width="140px;" fixed></el-table-column>
        <el-table-column prop="deviceName" :label="$t('device.nickname')" width="140px;"></el-table-column>
        <el-table-column prop="iccid" label="ICCID" width="105px;"></el-table-column>
<!--        <el-table-column prop="version" :label="$t('device.version')" width="90px;"></el-table-column>-->
        <el-table-column prop="deviceType" :label="$t('device.deviceType')" width="105px;" :formatter="deviceTypeFormatter"></el-table-column>
        <!-- <el-table-column prop="plate" :label="$t('device.plate')" width="105px;"></el-table-column> -->
        <!-- <el-table-column prop="timeZone" :label="$t('device.timeZone')" width="105px;"></el-table-column> -->
        <!-- <el-table-column prop="baseMileage" :label="$t('device.baseMileage')" width="105px;"></el-table-column> -->
        <!-- <el-table-column prop="deviceColour" :label="$t('device.deviceColour')" width="105px;"></el-table-column> -->
        <el-table-column :label="$t('device.onlineStatus')" width="105px;" :formatter="deviceOnlineStatusFormatter"></el-table-column>
        <el-table-column prop="lastLongitude" :label="$t('device.lastLongitude')" width="100px;"></el-table-column>
        <el-table-column prop="lastLatitude" :label="$t('device.lastLatitude')" width="90px;"></el-table-column>
        <el-table-column prop="lastLocationTime" :label="$t('device.lastLocationTime')" width="150px;"></el-table-column>
        <el-table-column prop="lastLocationType" :label="$t('device.lastLocationType')" width="110px;"
                         :formatter="locationTypeFormatter"></el-table-column>
        <el-table-column prop="lastSpeed" :label="$t('device.lastSpeed')" width="80px;"></el-table-column>
        <el-table-column prop="lastPositionDesc" :label="$t('device.lastPositionDesc')" width="110px;"></el-table-column>
        <el-table-column prop="expireDate" :label="$t('device.expireDate')" width="110px;"></el-table-column>
        <el-table-column prop="deviceRemark" :label="$t('device.deviceRemark')"></el-table-column>
        <!-- <el-table-column :label="$t('common.operation')" fixed="right" width="140px;">
          <template slot-scope="scope">
            <el-dropdown @command="handleOperation">
              <span class="el-dropdown-link operation">
                {{$t('devicePage.option.title')}}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ cmd: 'edit', deviceId: scope.row.deviceId }">{{$t('devicePage.option.edit')}}</el-dropdown-item>
                <el-dropdown-item :command="{ cmd: 'config', deviceId: scope.row.deviceId }">{{$t('devicePage.option.config')}}</el-dropdown-item>
                <el-dropdown-item :command="{ cmd: 'tank', deviceId: scope.row.deviceId }">{{$t('devicePage.option.tank')}}</el-dropdown-item>
                <el-dropdown-item :command="{ cmd: 'remove', deviceId: scope.row.deviceId }">{{$t('devicePage.option.remove')}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
           <!-- <el-button
             type="primary"
             size="mini"
             @click="editDevice(scope.row.deviceId)"
           >{{$t('devicePage.option.edit')}} </el-button>
           <el-button
             type="warning"
             size="mini"
             @click="editDevicePortConfig(scope.row.deviceId)"
           >{{$t('devicePage.option.config')}}</el-button>
           <el-button
             type="success"
             size="mini"
             @click="removeDevice(scope.row.deviceId)"
           >{{$t('devicePage.option.tank')}}</el-button>
           <el-button
             type="danger"
             size="mini"
             @click="removeDevice(scope.row.deviceId)"
           >{{$t('devicePage.option.remove')}}</el-button> -->
          <!-- </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="[20, 50, 100]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </el-card>
    <!-- 添加用户的对话框 -->
    <el-dialog :title="$t('devicePage.addDiagTitle')" :visible.sync="addDialogVisible" width="48%" @close="addDialogClosed">
      <!-- 内容主体 -->
      <el-form
        :model="addForm"
        ref="addFormRef"
        :rules="addFormRules"
        label-width="140px"
        label-position="right"
        class="addForm"
        size="mini"
      >
        <el-row>
          <el-form-item label="deviceId" v-if="false">
            <el-input v-model="addForm.deviceId" v-if="false"></el-input>
          </el-form-item>
          <el-col :span="12">
            <el-form-item :label="$t('device.deviceImei')" prop="deviceImei">
              <el-input v-model.trim="addForm.deviceImei"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('device.deviceName')" prop="deviceName">
              <el-input v-model.trim="addForm.deviceName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('device.deptId')" prop="deptId">
              <el-select
                v-model="addForm.deptId"
                :placeholder="$t('devicePage.deptPlaceholder')"
                size="small"
              >
                <el-option v-for="dept in deptList" :key="dept.deptId" :value="dept.deptId" :label="dept.deptName" > {{ dept.deptName }} </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('device.deviceType')" >
              <el-select v-model="addForm.deviceType"
                         size="small" :placeholder="$t('devicePage.deviceTypePlaceholder')">
                <el-option v-for="item in deviceTypeList" :key="parseInt(item.codeValue)" :value="parseInt(item.codeValue)" :label="item.codeName" > {{ item.codeName }} </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('device.bindMobile')" >
              <el-input v-model.trim="addForm.bindMobile"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('device.expireDate')" >
              <el-date-picker
                v-model="addForm.expireDate"
                align="right"
                type="date"
                value-format="yyyy-MM-dd"
                :format="this.$i18n.locale.includes('en')?'dd/MM/yyyy':'yyyy-MM-dd'"
                :placeholder="$t('userPage.expireDatePlaceholder')"
                :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('device.timeZone')" prop="timeZone">
              <el-input type="number" v-model="addForm.timeZone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('device.baseMileage')" prop="baseMileage">
              <el-input type="number" v-model="addForm.baseMileage"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item :label="$t('device.deviceRemark')">
              <el-input v-model.trim="addForm.deviceRemark" style="width: 450px;"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('device.deviceStatus')" v-if="addForm.deviceId !== undefined">
              <el-select v-model="addForm.deviceStatus"
                         size="small" >
                <el-option v-for="status in deviceStatusList" :key="parseInt(status.codeValue)" :value="parseInt(status.codeValue)" :label="status.codeName" > {{ status.codeName }} </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="addOrUpdate">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="$t('devicePage.tankDiagTitle')" :visible.sync="tankDialogVisible" width="50%" @close="tankDialogClosed" >
      <el-form
        :model="tankForm"
        ref="tankForm"
        :inline="true"
        label-width="110px"
        label-position="left"
        class="tankForm"
        size="mini"
      >
        <el-row >
          <el-form-item  v-if="false" >
            <el-input  v-model="tankForm.deviceId" v-if="false"></el-input>
          </el-form-item>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad1')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad1Max')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad1Max"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad1Min')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad1Min"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad2')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad2Max')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad2Max"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad2Min')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad2Min"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad3')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad3"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad3Max')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad3Max"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad3Min')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad3Min"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad4')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad4"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad4Max')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad4Max"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('device.ad4Min')">
              <el-input class="tankClass" type="number" v-model.trim="tankForm.ad4Min"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tankDialogVisible = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="updateTank">{{$t('common.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkLogin } from '@/api/globalProcessErr'
import {
  getGpsDeviceById,
  listGpsDevice,
  updateGpsDevice,
  insertGpsDevice,
  deleteGpsDevice,
  getDeviceTankByDeviceId,
  updateDeviceTankConfig
} from '@/api/gpsDevice'

import { listCodeByClass } from '@/api/code'
import { addMonth } from '@/api/dateFunc'
import { listDeptByCustomerId } from '@/api/sysDept'
import { getErrroDescStr } from '../../../api/enum'

export default {
  data () {
    let checkTimeZone = (rule, value, callback) => {
      if (isNaN(value) || value < -720 || value > 720) {
        return callback(new Error(this.$t('devicePage.rules.timeZone.validator') + ''))
      }
      return callback()
    }
    let checkBaseMileage = (rule, value, callback) => {
      if (isNaN(value) || value < 0) {
        return callback(new Error(this.$t('devicePage.rules.baseMileage.validator') + ''))
      }
      return callback()
    }
    return {
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() <= Date.now()
        },
        shortcuts: [{
          text: this.$t('devicePage.rules.expireDate.threeMonth'),
          onClick (picker) {
            const date = new Date()
            let newDate = addMonth(date, 3)
            picker.$emit('pick', newDate)
          }
        }, {
          text: this.$t('devicePage.rules.expireDate.sixMonth'),
          onClick (picker) {
            const date = new Date()
            let newDate = addMonth(date, 6)
            picker.$emit('pick', newDate)
          }
        }, {
          text: this.$t('devicePage.rules.expireDate.oneYear'),
          onClick (picker) {
            const date = new Date()
            let newDate = addMonth(date, 12)
            picker.$emit('pick', newDate)
          }
        }]
      },
      checkTimeZone: checkTimeZone,
      checkBaseMileage: checkBaseMileage,
      queryInfo: {
        deviceName: '',
        deviceImei: '',
        organization: '',
        bindMobile: '',
        deviceType: undefined,
        // 当前页数
        pageNum: 1,
        // 每页显示多少数据
        pageSize: 20
      },
      deviceTypeList: [
        this.$t('device.deviceTypeEnum.vehicleMountedLocator'),
        this.$t('device.deviceTypeEnum.pigeonLocator'),
        this.$t('device.deviceTypeEnum.studentCard'),
        this.$t('device.deviceTypeEnum.drivingRecorder')
      ],
      locationTypeList: [],
      deviceStatusList: [
        this.$t('device.deviceStatusEnum.online'),
        this.$t('device.deviceStatusEnum.offline')
      ],
      deviceColourList: [],
      deviceList: [{
        deviceId: 23,
        deviceImei: 68849789
      }],
      deptList: [],
      total: 0,
      // 添加用户对话框
      addDialogVisible: false,
      tankDialogVisible: false,
      // 用户添加
      addForm: {
        deviceId: undefined,
        deviceImei: '',
        deviceName: '',
        bindMobile: '',
        deviceType: 1,
        expireDate: '',
        timeZone: 0,
        baseMileage: 0,
        deviceRemark: '',
        deviceStatus: 1,
        deptId: undefined
      },
      tankForm: {
        deviceId: undefined,
        ad1Max: 0,
        ad1Min: 0,
        ad2Max: 0,
        ad2Min: 0,
        ad3Max: 0,
        ad3Min: 0,
        ad4Max: 0,
        ad4Min: 0
      }
    }
  },
  computed: {
    addFormRules () {
      return {
        bindMobile: [
          { required: true, message: this.$t('devicePage.rules.bindMobile.required'), trigger: 'blur' }
        ],
        deviceImei: [
          { required: true, message: this.$t('devicePage.rules.deviceImei.required'), trigger: 'blur' }
        ],
        deviceName: [
          { required: true, message: this.$t('devicePage.rules.deviceName.required'), trigger: 'blur' }
        ],
        deptId: [
          { required: true, message: this.$t('devicePage.rules.deptId.required'), trigger: 'blur' }
        ],
        timeZone: [
          // { required: true, message: this.$t('devicePage.rules.timeZone.required'), trigger: 'blur' },
          { required: false },
          { type: 'number', message: this.$t('devicePage.rules.timeZone.typeCheck'), trigger: 'blur', transform: (value) => Number(value) },
          { validator: this.checkTimeZone, trigger: 'blur' }
        ],
        baseMileage: [
          { required: false },
          { type: 'number', message: this.$t('devicePage.rules.baseMileage.typeCheck'), trigger: 'blur', transform: (value) => Number(value) },
          { validator: this.checkBaseMileage, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.$i18n.locale = window.sessionStorage.getItem('lang')
    this.getDeviceList()
    // this.getLocationTypeList()
    // this.getDeviceColourList()
    // this.listDept()
  },
  methods: {
    getDeviceList () {
      listGpsDevice().then(res => {
        checkLogin(res, this.$message)
        if (res.data.code === 0) {
          this.deviceList = res.data.data
          this.total = res.data.data.length || 0
        } else {
          this.$message.error(getErrroDescStr(this, res.data.code))
        }
      })
    },
    listDept () {
      const self = this
      let customerId = parseInt(window.sessionStorage.getItem('selfCustomerId'))
      listDeptByCustomerId(customerId).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.deptList = res.data.data
        if (self.deptList.length === 1) {
          self.addForm.deptId = self.deptList[0].deptId
        }
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    handleOperation (data) {
      switch (data.cmd) {
        case 'edit':
          this.editDevice(data.deviceId)
          break
        case 'config':
          this.editDevicePortConfig(data.deviceId)
          break
        case 'tank':
          this.editTank(data.deviceId)
          break
        case 'remove':
          this.removeDevice(data.deviceId)
          break
        default:
      }
    },
    updateTank () {
      const self = this
      updateDeviceTankConfig(self.tankForm).then(res => {
        checkLogin(res)
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.$message.success(self.$t('common.updateSuccess'))
        self.tankDialogVisible = false
      }).catch(err => {
        self.$message.error(err.message)
      })
    },
    addOrUpdate () {
      const self = this
      self.$refs.addFormRef.validate(async valid => {
        // 表单预校验失败
        if (!valid) return
        if (self.addForm.deviceId === undefined) {
          const req = self.addForm
          insertGpsDevice(req).then(res => {
            if (res.data.code === 3008) {
              return self.$message.error(self.$t('devicePage.rules.insert.imeiExists'))
            }
            if (res.data.code === 3009) {
              return self.$message.error(self.$t('devicePage.rules.insert.nameExists'))
            }
            if (res.data.code !== 0) {
              return self.$message.error(res.data.msg)
            }
            self.$message.success(self.$t('common.createSuccess'))
            self.getDeviceList()
          }).catch(err => {
            return self.$message.error(err.message)
          })
        } else {
          updateGpsDevice(self.addForm).then(res => {
            if (res.data.code !== 0) {
              return self.$message.error(res.data.msg)
            }
            self.$message.success(self.$t('common.updateSuccess'))
            self.getDeviceList()
          }).catch(err => {
            return self.$message.error(err.message)
          })
        }
        // 隐藏添加用户对话框
        this.addDialogVisible = false
      })
    },
    async removeDevice (deviceId) {
      const self = this
      const confirmResult = await this.$confirm(
        self.$t('common.deleteConfirm'),
        self.$t('common.notice'),
        {
          confirmButtonText: self.$t('common.confirm'),
          cancelButtonText: self.$t('common.cancel'),
          type: 'warning'
        }
      ).catch(err => err)
      // 点击确定 返回值为：confirm
      // 点击取消 返回值为： cancel
      if (confirmResult !== 'confirm') {
        return this.$message.success(self.$t('common.canceled'))
      }
      deleteGpsDevice(deviceId).then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.$message.info(self.$t('common.deleteSuccess'))
        this.getDeviceList()
      }).catch(err => {
        return this.$message.error(err.message)
      })
    },
    getLocationTypeList () {
      listCodeByClass('LOCATION_TYPE').then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.locationTypeList = res.data.data
      }).catch(err => {
        this.$message.error(err.message)
      })
    },
    getDeviceColourList () {
      listCodeByClass('DEVICE_COLOUR').then(res => {
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.deviceColourList = res.data.data
      }).catch(err => {
        this.$message.error(err.message)
      })
    },
    deviceTypeFormatter (row, column) {
      for (let i = 1; i < this.deviceTypeList.length; i++) {
        if (row.deviceType === i) {
          return this.deviceTypeList[i - 1]
        }
      }
    },
    deviceOnlineStatusFormatter (row, column) {
      if (row.onlineStatus) {
        return this.deviceStatusList[0]
      } else {
        return this.deviceStatusList[1]
      }
    },
    locationTypeFormatter (row, column) {
      for (let i = 0; i < this.locationTypeList.length; i++) {
        if (row.lastLocationType === parseInt(this.locationTypeList[i].codeValue)) {
          return this.locationTypeList[i].codeName
        }
      }
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getDeviceList()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pageNum = newSize
      this.getDeviceList()
    },
    // 监听 添加对话框的关闭事件
    addDialogClosed () {
      this.$refs.addFormRef.resetFields()
    },
    tankDialogClosed () {
      this.$refs.tankForm.resetFields()
    },
    initAdd () {
      this.addDialogVisible = true
      this.addForm = {
        deviceId: undefined,
        deviceImei: '',
        deviceName: '',
        bindMobile: '',
        deviceType: 1,
        timeZone: 0,
        baseMileage: 0,
        deviceRemark: '',
        deviceStatus: 1,
        deptId: undefined,
        expireDate: ''
      }
      this.listDept()
    },
    editDevice (deviceId) {
      const self = this
      getGpsDeviceById(deviceId).then(res => {
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.addForm = res.data.data
        self.addDialogVisible = true
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    editTank (deviceId) {
      const self = this
      getDeviceTankByDeviceId(deviceId).then(res => {
        if (res.data.code !== 0) {
          return self.$message.error(res.data.msg)
        }
        self.tankForm = res.data.data
        self.tankDialogVisible = true
      }).catch(err => {
        return self.$message.error(err.message)
      })
    },
    resetQuery () {
      // this.$refs['queryInfoRef'].resetFields()
      this.queryInfo.deviceImei = ''
      this.queryInfo.userName = ''
      this.queryInfo.deviceName = ''
      this.queryInfo.organization = ''
      this.queryInfo.bindMobile = ''
      this.queryInfo.deviceType = undefined
      this.pageNum = 1
      this.pageSize = 20
      this.getDeviceList()
    },
    editDevicePortConfig (deviceId) {
      window.sessionStorage.setItem('PortConfig:DeviceId', deviceId)
      this.$router.push({
        name: 'portConfig',
        params: {
          deviceId: deviceId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-card {
  .el-form {
    .el-col {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;

      .el-form-item {
        font-size: xx-small;
        width: 260px;
        padding-left: 0;
        padding-right: 0;
        margin-left: 0;
        margin-right: 0;

        .el-input {
          width: 150px;
          margin-right: 0;
          margin-left: 0;
        }
      }

      .el-select {
        width: 150px;
      }
    }
  }
}

.el-dialog {
  .el-form {
    width: 100% !important;

    .el-row {
      .el-col {
        .el-input {
          width: 150px;
        }

        .el-select {
          width: 150px;
        }
      }
    }
  }
}

.el-table {
  .el-table-column {
  }
}

.tableArea {
  padding-right: 0 !important;
  padding-left: 0 !important;
  width: 95% !important;
}

.page {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.noBoard {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.addForm {
  width: 650px !important;
}

.operation {
  color: #108cf8 !important;
}

.tankClass {
  width: 100px !important;
  margin-left: 0!important;
}

</style>
