import request from '@/api/request'

export function listDept (data) {
  const query = {
    deptName: data.deptName,
    customerName: data.customerName,
    pageNum: data.pageNum,
    pageSize: data.pageSize
  }
  return request({
    url: 'dept/list',
    method: 'post',
    data: query
  })
}

export function getDeptByDeptId (deptId) {
  return request({
    url: 'dept/getDeptByDeptId/' + deptId,
    method: 'get'
  })
}

export function listDeptByCustomerId (customerId) {
  return request({
    url: 'dept/listDeptByCustomerId/' + customerId,
    method: 'get'
  })
}

export function create (data) {
  const dept = {
    deptName: data.deptName,
    customerId: data.customerId
  }
  return request({
    url: 'dept/create',
    method: 'post',
    data: dept
  })
}

export function deleteDeptByDeptId (deptId) {
  const dept = {
    deptId: deptId
  }
  return request({
    url: 'dept/delete',
    method: 'post',
    data: dept
  })
}
